import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout";
import * as React from "react";

// <div style={{ width: '49%', display: 'inline-block' }}>
//   <img src="/stacks/stack-2019-01.png" />
// </div>
// <div style={{ width: '49%', display: 'inline-block' }}>
//   <img src="/stacks/stack-2022-01.png" />
// </div>
const StacksScreen = () => (
  <Layout>
    <div style={{ display: 'flex'}}>
    <div style={{ width: '49%' }}>
      <h2>2019</h2>
      <img src="/stacks/stack-2019-01.png" />
      <img src="/stacks/stack-2019-02.png" />
      <img src="/stacks/stack-2019-03.png" />
      <img src="/stacks/stack-2019-04.png" />
    </div>
    <div style={{ width: '49%' }}>
      <h2>2022</h2>
      <img src="/stacks/stack-2022-01.png" />
      <img src="/stacks/stack-2022-02.png" />
      <img src="/stacks/stack-2022-03.png" />
      <img src="/stacks/stack-2022-04.png" />
      <img src="/stacks/stack-2022-05.png" />
    </div>
    </div>
  </Layout>
)

export default StacksScreen